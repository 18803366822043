/** Green	#8ab933
 **************************************************************** **/
	::selection {
		background: #8ab933;
	}
	::-moz-selection {
		background: #8ab933; /* Firefox */
	}
	::-webkit-selection {
		background: #8ab933; /* Safari */
	}


	a,
	a:focus:not(.btn),
	section.dark a,
	#slidetop a:hover,
	h1 > span,
	h2 > span,
	h3 > span,
	h4 > span,
	h5 > span,
	h6 > span,
	.pagination>li>a, 
	.pagination>li>a:hover, 
	.pagination>li>span,
	ul.list-links>li>a:hover,
	#sidepanel ul li a[data-toggle="collapse"],
	#sidepanel ul li a[data-toggle="collapse"]:hover,
	#sidepanel ul li a:hover,
	.menu-list ul li a:hover, 		/* header-0.css */
	.menu-list ul li.active a, 		/* header-0.css */
	#menu_overlay_close:hover, 	/* header-0.css */
	#topMain.nav-pills>li.active>a>span.topMain-icon,
	#page-menu.page-menu-light ul>li:hover>a>i,
	#page-menu.page-menu-dark ul>li:hover>a>i,
	#page-menu.page-menu-transparent ul>li:hover>a>i,
	#page-menu.page-menu-light ul>li.active>a>i,
	#page-menu.page-menu-dark ul>li.active>a>i,
	#page-menu.page-menu-transparent ul>li.active>a>i,
	.tp-caption.block_white,
	section span.theme-color, 
	section em.theme-color, 
	section i.theme-color, 
	section b.theme-color, 
	section div.theme-color, 
	section p.theme-color,
	.owl-carousel.featured a.figure>span>i,
	.owl-carousel.featured a:hover,
	figure.zoom>a.lightbox:hover,
	#portfolio h2>a:hover,
	#portfolio h3>a:hover,
	#portfolio h4>a:hover,
	#portfolio h5>a:hover,
	#portfolio h6>a:hover,
	.tab-post a:hover,
	.tag:hover>span.txt,
	section.dark .tag:hover>span.txt,
	ul.widget-twitter li>small>a:hover,
	.blog-post-item h2>a:hover,
	.blog-post-item h3>a:hover,
	.blog-post-item h4>a:hover,
	.blog-post-item h5>a:hover,
	.item-box .item-hover .inner .ico-rounded:hover>span,
	ul.side-nav>li:hover>a,
	ul.side-nav>li a:hover,
	ul.side-nav>li.active>a,
	a.href-reset:hover,
	a.href-reset.active,
	.href-reset a.active,
	.href-reset a:hover,
	.href-reset a.active,
	.price-clean-popular h4,
	.box-icon.box-icon-color i,
	.switch-primary.switch-round > input:checked + .switch-label:after,
	section.dark .nav-tabs.nav-clean>li.active>a { 
		color: #8ab933; 
	}
	
	#sidepanel.sidepanel-theme-color,
	div.alert.alert-theme-color,
	div.alert.alert-primary,
	span.badge-default,
	span.label-default,
	.list-group-item.active>.badge.badge-default,
	.nav-tabs.nav-alternate>li.active>a,
	.datepicker table tr td.active.active,
	.callout-theme-color,
	.progress-bar-primary,
	.toast-primary,
	.owl-theme .owl-controls .owl-page span,
	.open>.dropdown-toggle.btn-primary,
	.btn-primary,
	.btn-primary:hover,
	.btn-default:hover,
	.btn-primary:active,
	.btn-primary:focus,
	.pagination>.active>a,
	.pagination>.active>a:hover,
	#header li.quick-cart .quick-cart-box a.btn,
	#header li.quick-cart .quick-cart-box a.btn:hover, /* used by dark & transparent header */
	#topMain.nav-pills>li.active>a>span.theme-color ,
	.ei-slider-thumbs li.ei-slider-element,			/* elastic slider active indicator */
	.flex-control-paging li a.flex-active,				/* flex slider bullets */
	.tp-caption.block_theme_color,
	i.ico-color,
	.caption-primary,
	.shop-list-options .btn.active,
	.box-static.box-color,
	.switch.switch-primary > input:checked + .switch-label,
	.fancy-file-upload.fancy-file-primary>span.button,
	.primary-slider .ui-slider .ui-slider-handle:before,
	.primary-slider .ui-slider .ui-slider-range,
	.ribbon-inner,
	.info-bar.info-bar-color,
	.music-album-title:hover,
	.inews-item .inews-thumbnail .inews-sticky,
	#progressBar #bar,								/* OWL */
	#page-menu,
	#page-menu ul,
	#slider .btn-default:hover {
		background-color:#8ab933;
		color: #fff;
	}

	/* !important required */
	#music-player .mejs-controls .mejs-time-rail .mejs-time-current,
	#music-player .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
	section .theme-background,
	.swiper-pagination-bullet-active {
		background-color:#8ab933 !important;
	}
	.inews-content-inner h3>a,
	div.side-nav ul>li:hover>a, 
	div.side-nav ul>li.active>a,
	.pagination.pagination-simple>li.active>a,
	div.side-nav ul.list-group-bordered>li>a:hover,
	section .theme-color {
		color:#8ab933 !important;
	}

	.primary-slider .ui-slider .ui-slider-handle,
	.switch.switch-primary > input:checked + .switch-label,
	.timeline_center li h3:before, 
	.timeline_center:after,
	a.thumbnail.active,
	a.thumbnail:hover,
	.hvr-reveal:before,
	.btn-primary,
	.btn-primary:hover,
	.btn-primary:active,
	.btn-primary:focus {
		border-color:#8ab933;
	}

	.open>.dropdown-toggle.btn-primary,
	.btn-primary,
	.btn-primary:active,
	.btn-primary:focus,
	.btn-primary:hover
	.pagination>.active>a,
	.pagination>.active>a:hover {
		border-color: #8ab933;
	}

	#topNav ul.dropdown-menu { /* submenu border top color */
		border-top-color:#8ab933;
	}
	section.featured-grid div.row>div .ribbon:before,
	.modal-content {
		border-top-color:#8ab933;
	}

	#topNav ul.dropdown-menu {
		border-color: #8ab933 #fff #fff;
	}
	#topNav div.submenu-dark ul.dropdown-menu {
		border-color: #8ab933 #3F3F3F #3F3F3F;
	}
	#topNav div.submenu-color ul.dropdown-menu {
		border-color: #8ab933 #3F3F3F #3F3F3F;
	}
	#topMain li.search .search-box {
		border-top: #8ab933 2px solid;
	}


	/* COLOR DROP DOWN MENU */
	#topNav div.submenu-color ul.dropdown-menu {
		background-color:#8ab933;
	}
	#topNav div.submenu-color ul.dropdown-menu {
		border-color: #888 #8ab933 #8ab933;
	}

	#topNav div.submenu-color ul.dropdown-menu a.dropdown-toggle {
		background-image: url('../../images/submenu_light.png');
	}
	#topNav div.submenu-color ul.dropdown-menu li.active>a, 
	#topNav div.submenu-color ul.dropdown-menu li.active:hover>a, 
	#topNav div.submenu-color ul.dropdown-menu li.active:focus>a, 
	#topNav div.submenu-color ul.dropdown-menu li:hover>a, 
	#topNav div.submenu-color ul.dropdown-menu li:focus>a, 
	#topNav div.submenu-color ul.dropdown-menu li:focus>a {
		color:#fff;
		background-color:rgba(0,0,0,0.15);
	}
	#topNav div.submenu-color ul.dropdown-menu li.divider {
		background-color:rgba(255,255,255,0.3);
	}
	#topNav div.submenu-color ul.dropdown-menu>li a {
		color:#fff;
	}
	#topNav div.submenu-color #topMain>li.mega-menu div.row div>ul>li>span {
		color:#fff;
	}
	#topNav div.submenu-color #topMain>li.mega-menu div.row div { 
		border-left: rgba(0,0,0,0.2) 1px solid;
	}

	
	/* Theme Color Section */
	section.theme-color,
	section.theme-color div.heading-title.heading-dotted h1,
	section.theme-color div.heading-title.heading-dotted h2,
	section.theme-color div.heading-title.heading-dotted h3,
	section.theme-color div.heading-title.heading-dotted h4,
	section.theme-color div.heading-title.heading-dotted h5,
	section.theme-color div.heading-title.heading-dotted h6 {
		color:#fff;
		background-color: #8ab933;
	}
	section.theme-color div.heading-title h1, 
	section.theme-color div.heading-title h2, 
	section.theme-color div.heading-title h3, 
	section.theme-color div.heading-title h4, 
	section.theme-color div.heading-title h5, 
	section.theme-color div.heading-title h6 {
		background-color: #8ab933;
	}
	section.theme-color div.heading-title h1 span, 
	section.theme-color div.heading-title h2 span, 
	section.theme-color div.heading-title h3 span, 
	section.theme-color div.heading-title h4 span, 
	section.theme-color div.heading-title h5 span, 
	section.theme-color div.heading-title h6 span {
		color:#fff;
	}
	section.theme-color a {
		color:#fff;
		text-decoration:none;
	}
	section.theme-color a:hover {
		color:#111;
	}
	section.theme-color pre {
		background-color:rgba(0,0,0,0.1);
	}
	section.theme-color div.heading-border-bottom.heading-color {
	  border-bottom-color:rgba(255,255,255,0.5);
	}




/**	Vertical Menu
*************************************************** **/
body.menu-vertical #mainMenu.sidebar-vertical .sidebar-nav .navbar li:hover>a,
body.menu-vertical #mainMenu.sidebar-vertical .dropdown-menu>.active>a, 
body.menu-vertical #mainMenu.sidebar-vertical .dropdown-menu>.active>a:focus, 
body.menu-vertical #mainMenu.sidebar-vertical .dropdown-menu>.active>a:hover,
body.menu-vertical #mainMenu.sidebar-vertical .navbar-default .navbar-nav>.active>a,
body.menu-vertical #mainMenu.sidebar-vertical .sidebar-nav .navbar li:hover>a {
	color:#8ab933 !important;
}





/**	[Shortcode] Buttons
*************************************************** **/
section.dark .btn-default:hover {
	color: #8ab933;
	background-color:rgba(0,0,0,0.3);
}


/**	[Shortcode] Page Header
*************************************************** **/
	section.page-header .breadcrumb a:hover  {
		color: #8ab933 !important; 
		text-decoration:none;
	}
	
	
/**	[Shortcode] Dividers
*************************************************** **/
	div.divider.divider-color i {
		color:#fff;
	}
	div.divider.divider-color:before,
	div.divider.divider-color:after {
		border-top:#8ab933 1px solid;
	}
	div.divider.divider-color i {
		color:#8ab933;
	}
	div.divider.divider-circle.divider-color i {
		color:#fff;
		background-color: #8ab933;
	}
	div.divider.divider-border>a:hover>i {
		color:#8ab933;
	}


/**	[Shortcode] Headings
*************************************************** **/
	div.heading-title.heading-line-single.heading-color:before,
	div.heading-title.heading-line-double.heading-color:before {
		border-color:#8ab933;
	}
	div.heading-border-bottom.heading-color {
		border-bottom-color:#8ab933;
	}
	div.heading-title.heading-border.heading-color,
	div.heading-title.heading-border.heading-inverse.heading-color {
		border-color:#8ab933;
	}
	section.dark div.heading-title.heading-border-bottom {
		border-bottom-color:#8ab933;
	}

/**	[Shortcode] Icon Boxes
*************************************************** **/
.box-icon a.box-icon-title:hover>h2 {
	color:#8ab933;
}
.box-icon a.box-icon-title:hover>i {
	color:#fff;
	background-color:#8ab933;
}
.box-icon a.box-icon-more {
	color:#111;
}
.box-icon a.box-icon-more:hover,
section.dark .box-icon a.box-icon-more:hover {
	color:#8ab933;
}

.box-video a.box-video-title:hover h2,
.box-video a.box-image-title:hover h2 {
	color:#8ab933;
}

.box-flip .box2 {
	color:#fff;
	background-color:#8ab933;
}

.box-flip .box2 h1,
.box-flip .box2 h2,
.box-flip .box2 h3,
.box-flip .box2 h4,
.box-flip .box2 h5,
.box-flip .box2 h6 {
	color:#fff;
}

.box-static.box-border-top {
	border-color:#8ab933;
}



/**	[Shortcode] Navigations
*************************************************** **/
.navbar-primary {
	border-color:#8ab933 !important;
	background-color:#8ab933 !important;
}


/**	[Shortcode] Paginations
*************************************************** **/
section.dark .pagination > li.active>a,
.pagination > li.active>a {
	border-color:#8ab933;
	background-color:#8ab933;
}


/** [Shortcode] Process Steps
*************************************************** **/
.process-wizard-primary > .process-wizard-step > .process-wizard-dot,
.process-wizard-primary > .process-wizard-step > .progress > .progress-bar {
	background:#8ab933;
}
.process-wizard-primary > .process-wizard-step > .process-wizard-dot:after {
	background-color:rgba(0,0,0,0.4);
}

ul.process-steps li.active a,
ul.process-steps li.active a:focus,
ul.process-steps li.active:hover>a {
	background-color:#8ab933 !important;
	border-color:#8ab933 !important;
}

ul.process-steps li.active h1,
ul.process-steps li.active h2,
ul.process-steps li.active h3,
ul.process-steps li.active h4,
ul.process-steps li.active h5,
ul.process-steps li.active h6 {
	color:#8ab933;
}



/** [Shortcode] Process Steps
*************************************************** **/
div.mega-price-table .pricing-title, 
div.mega-price-table .pricing-head, 
div.mega-price-table .pricing.popular {
	background-color:#8ab933;
}


div.mega-price-table .pricing:hover h4, 
div.mega-price-table .pricing-table i.fa {
	color:#8ab933;
}



/** Styled Icons
 **************************************************************** **/
section.dark i.ico-hover:hover,
i.ico-hover:hover {
	background-color:#8ab933;
	border-color:#8ab933;
}


/** [Shortcode] Tabs
 **************************************************************** **/
.nav-tabs.nav-top-border>li.active>a,
.nav-tabs.nav-top-border>li.active>a:hover {
	border-top-color:#8ab933 !important;
}
.nav-tabs.nav-bottom-border>li.active>a,
.nav-tabs.nav-bottom-border>li.active>a:hover {
	border-bottom-color:#8ab933 !important;
}
.nav-tabs.nav-alternate>li.active>a {
	background-color: #8ab933 !important;
}
.nav-tabs>li.active>a {
	color: #8ab933 !important;
}



/** Sky Forms
 **************************************************************** **/
/**/
/* normal state */
/**/
.sky-form .toggle i:before {
	background-color: #8ab933;	
}
.sky-form .button {
	background-color: #8ab933;
}


/**/
/* checked state */
/**/
.sky-form .radio input + i:after {
	background-color: #8ab933;	
}
.sky-form .checkbox input + i:after {
	color: #8ab933;
}
.sky-form .radio input:checked + i,
.sky-form .checkbox input:checked + i,
.sky-form .toggle input:checked + i {
	border-color: #8ab933;	
}
.sky-form .rating input:checked ~ label {
	color: #8ab933;	
}


/** Hover Buttons
 **************************************************************** **/
.hvr-border-fade:hover, .hvr-border-fade:focus, .hvr-border-fade:active {
    box-shadow: inset 0 0 0 4px #8ab933, 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-fade:hover, .hvr-fade:focus, .hvr-fade:active,
.hvr-back-pulse:hover, .hvr-back-pulse:focus, .hvr-back-pulse:active,
.hvr-sweep-to-right:before,
.hvr-sweep-to-left:before,
.hvr-sweep-to-bottom:before,
.hvr-sweep-to-top:before,
.hvr-bounce-to-right:before,
.hvr-bounce-to-left:before,
.hvr-bounce-to-bottom:before,
.hvr-bounce-to-top:before ,
.hvr-radial-out:before,
.hvr-radial-in,
.hvr-rectangle-inm
.hvr-rectangle-out:before,
.hvr-shutter-in-horizontal,
.hvr-shutter-out-horizontal:before,
.hvr-shutter-in-vertical,
.hvr-shutter-out-vertical:before,
.hvr-underline-from-left:before,
.hvr-underline-from-center:before,
.hvr-underline-from-right:before,
.hvr-overline-from-left:before,
.hvr-overline-from-center:before,
.hvr-overline-from-right:before,
.hvr-underline-reveal:before,
.hvr-overline-reveal:before {
	background-color: #8ab933;
}